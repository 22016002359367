<template>
  <div class="ShippingPage">
    <div class="ShippingPage__description">
      <h1>Friends of {{ siteConstants.siteName }}</h1>
      <h3>Become our affiliate and earn money!</h3>
      Join our affiliate programme and generate an extra way to grow your
      revenue with the leading plant-based shopping platform.
      <br>
      Promote our marketplace anywhere via our referral link and earn money.
      Refer visitors to <router-link to="/">vegankind.com</router-link> and
      earn a commission for the customer's purchase value. We look forward
      to working with you!
      <br>
      <h3>Why join the {{ siteConstants.siteName }} partners programme?</h3>
      <router-link to="/">Vegankind.com</router-link> marketplace sells more
      than 10,000 plant-based and vegan products across dairy and meat
      alternatives, whole foods, pantry essentials, beverages, snacks, sweets,
      health and beauty products, cosmetics, cleaning and household items.
      <br>
      If you are a food and recipe creator, an influencer, a blogger, promoting
      a healthy and sustainable lifestyle, and have a website or social media account
      with following where you share related lifestyle content, this is a fantastic
      way for you to start earning a 10% commission from each sale referred by you.
      <br>
      <h3>Easy, comprehensive dashboard</h3>
      Our affiliate dashboard offers a comprehensive look at your clicks, sales
      and money you made. You can view your earnings and track your performance,
      and you will get paid every month.
      <br>
      <br>
      <a href="https://friends.vegankind.com/" target="_blank">Join Here</a>
    </div>
  </div>
</template>

<script>
// import VueMarkdown from 'vue-markdown'
import siteConstants from '@/const.js'

export default {
  name: 'AffiliatePage',
  components: {
    // VueMarkdown
  },
  data () {
    return {
      siteConstants
    }
  }
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.ShippingPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0
  box-sizing border-box
  &__icon
    margin-top 70px
    font-size 140px
    display inline-block
  &__description
    text-align left
    line-height 1.6
    max-width 650px
    display inline-block
    box-sizing border-box
    font-size 14px
    /deep/ h1
      font-size 27px
      font-weight 300
      color black
    h1
      font-family: 'Bebas Neue', sans-serif;
    h2, h3
      font-family: Helvetica
      margin 30px 0 10px 0

@media screen and (max-width: 868px)
  .ShippingPage
    padding 30px 10px
</style>
